const data = {
    logoPart1 :'MARKET ',
    logoPart2: 'PROSPECTUS',
    fullname: 'Market Prospectus',
    logoSubtitle: 'Market Prospectus accumulate and examine raw data across 12 major B2B verticals to provide market insight that enables companies to get competitive edge and detect market disruptions early.',
    companyName:'AMA Research & Media LLP',
    website:'www.marketprospectus.com',
    email:'adam.b@marketprospectus.com',
    REACT_APP_EMAIL_SERVICE_ID:'service_m09jji4',
    REACT_APP_EMAIL_TEMPLATE_ID : 'template_i87w55v',
    REACT_APP_EMAIL_UNSUBSCIBE_TEMPLATE : 'template_sznv2i9',
    REACT_APP_EMAIL_PUBLIC_KEY :'ISeTXwqyuNTN_NsZv'
}

export default data;